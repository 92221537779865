<template>
  <div class="administrative-level-filter">
    <multiselect
      :value="valueObj"
      ref="level-input"
      class="bordered"
      placeholder=""
      :searchable="true"
      :internal-search="true"
      :preserveSearch="false"
      :clearOnSelect="false"
      :show-no-options="true"
      :hide-selected="false"
      :options="options"
      track-by="value"
      label="name"
      openDirection="bottom"
      selectLabel=""
      deselectLabel="Selected"
      @select="onSelect"
    >
      <close-icon
        v-if="(valueObj.value || valueObj.value === 0) && !isMultiselectOpened()"
        slot="caret"
        @mousedown.prevent.stop="clear()"
        class="caret-icon"
      ></close-icon>
      <span slot="noResult" class="input-helper">Level not found</span>
      <span slot="singleLabel" slot-scope="props">
        <template>{{ props.option.name || 'All Administrative Levels' }}</template>
      </span>
    </multiselect>
  </div>
</template>

<script>
import {LEVELS} from '@common/utils/utils.place';
import CloseIcon from 'vue-material-design-icons/Close';
import Multiselect from 'vue-multiselect';

export default {
  props: {
    value: Number,
  },
  computed: {
    options() {
      return [
        {value: LEVELS.province, name: 'Province'},
        {value: LEVELS.city, name: 'Prefecture City'},
        {value: LEVELS.district, name: 'County/District'},
        {value: LEVELS.town, name: 'Town/Subdistrict'},
        {value: LEVELS.village, name: 'Village'},
        {value: LEVELS.sub_village, name: 'Subvillage'},
      ];
    },
    valueObj() {
      return this.options.find(item => item.value === this.value) || {};
    },
  },
  methods: {
    onSelect(option) {
      const value = option ? option.value : null;
      this.$emit('select', value);
    },
    isMultiselectOpened() {
      return this.$refs['level-input'] && this.$refs['level-input'].isOpen;
    },
    clear() {
      this.onSelect(null);
    },
  },
  components: {Multiselect, CloseIcon},
};
</script>

<style scoped></style>
